export const info = {
  generic:
    "Nato a Crema il 5 maggio 1989, residente in Noceto, piazza partigiani, 43015 (PR)",
  cell: "348 534 1851",
  email: "lucaneot@gmail.com",
};

export const formazione = [
  "Diploma di istruzione secondaria superiore presso I.T.I.S G.Galilei di Crema, qualifica di perito capotecnico industriale specializzazione informatica",
  "Corso triennale (scuola superiore) dei linguaggi di programmazione C e Java, e dei linguaggi interpretati HTML, JavaScript e CSS",
  "Attestato ECDL Base e ECDL Advanced moduli AM3, AM4, AM5 e AM6",
  "Formazione aziendale per frameworks Struts, Hibernate, Spring, GWT, JPA ed EJB per la programmazione j2EE",
  "Formazione aziendale per framework React",
  "Corso base MongoDB online",
  "AWS Business Professional, AWS TCO and Cloud Economics (Digital) certificates (giugno 2019)",
];

export const esperienze = [
  {
    start: "Ottobre 2021",
    end: "-",
    company: "Profesia",
    task: "Software architect",
    description:
      "Ingegnerizzazione di sistemi API manager presso clienti. Sviluppi in piattaforma per un'importante multinazionale in ambito energetico.",
  },
  {
    start: "Giugno 2017",
    end: "Ottobre 2021",
    company: "Sinfo One",
    task: "Full stack developer",
    description:
      "Programmatore java in ambito ERP. Sviluppo di servizi back-end java e interfacce front-end js per portabilità su web del prodotto aziendale.",
  },
  {
    start: "Febbraio 2014",
    end: "Maggio 2017",
    company: "Advancia Technology",
    task: "Java developer",
    description:
      "Programmatore java in ambito JEE di servizi per antiriciclaggio per banche e assicurazioni.",
  },
  {
    start: "Novembre 2011",
    end: "Maggio 2013",
    company: "Dinamica",
    task: "Operatore cnc",
    description:
      "Programmazione macchine utensili CNC. Realizzazione di parti prototipali per il settore automotive, stampi rotazionali e attrezzature meccaniche di macchinari industriali.",
  },
  {
    start: "Dicembre 2009",
    end: "Ottobre 2011",
    company: "Deltastudio",
    task: "Tecnico multimediale",
    description:
      "Gestione di impianti multimediali, reti dati e telefoniche dell'ateneo dell'Univerisità Cattolica del Sacro Cuore di Milano.",
  },
  {
    start: "Aprile 2008",
    end: "Settembre 2008",
    company: "Colzani elettrica",
    task: "Elettricista",
    description:
      "Esperienze come elettricista in campo civile ed industriale (Rhom and Haas) ed operatore di linea per Galbani durante gli studi superiori.",
  },
];

export const competenze = [
  { "Linguaggi di programmazione": ["Java", "C", "Javascript", "Python"] },
  {
    "Freameworks back-end": [
      "Spring boot",
      "Spring Security",
      "Spring Batch",
      "Spring Data",
      "GWT",
      "Struts",
      "Hibernate",
      "JPA",
      "Liquibase",
      "Querydsl",
    ],
  },
  {
    "Front-end library": [
      "React",
      "Redux",
      "Material - ui",
      "i18next",
      "Immer",
    ],
  },
  { "Application Server": ["Tomcat", "JBoss"] },
  { "Software IDE": ["Eclipse", "Netbeans", "IntelliJ", "VSCode"] },
  { Ambienti: ["linux based", "Windows"] },
  {
    Strumenti: [
      "Git",
      "Jira",
      "Docker",
      "Kubernetes",
      "Pipeline (GitLab, GitHub)",
    ],
  },
  {
    Database: [
      "Oracle",
      "MySql",
      "PostgreSQL",
      "Microsoft SQL",
      "IBM DB2",
      "MongoDB",
      "DynamoDB",
    ],
  },
  { Office: ["Word", "Excel", "Access", "Power Point"] },
  { OpenOffice: ["Writer", "Calc", "Base"] },
  { AWS: ["Lambda", "S3", "Amplify", "DynamoDB", "CodeCommit", "CloudWatch"] },
];

export const altro = [
  "Ottime doti organizzative",
  "Ottimo spirito collaborativo",
  "Elevata flessibilità e dinamicità",
  "Ottimo spirito d'Iniziativa",
  "Attitudine al continuo miglioramento",
  "Curiosità",
  "Socio Mensa Italia",
];

export const autorizzazione =
  "Autorizzo il trattamento dei dati personali al solo scopo di selezione del personale ai sensi del D. Lgs. 196/2003";
